import React from 'react'
import { graphql, Link } from 'gatsby'

import Layout from '../components/layout-old'
import SEO from '../components/seo'
import { rhythm, scale } from '../utils/typography'

const BlogPage = ({ data }) => (
  <div style={{
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: rhythm(24),
    padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
  }}>
    <SEO title="Blog" keywords={['gatsby', 'application', 'react']} />
    <p>This is the blog index page</p>
    <div style={{ margin: '1.45rem' }}>
    {data.allMarkdownRemark.edges.map(post => (
        <div key= { post.node.id }>
            <h3>{ post.node.frontmatter.title }</h3>
            <Link to={post.node.frontmatter.path}>Read More</Link>
            <br />
            <br />
            <hr />
        </div>
    ))}
    </div>
  </div>
)

export const pageQuery = graphql`
    query BlogIndexQuery {
        allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
            edges {
                node {
                    id
                    frontmatter {
                        path
                        title
                        author
                        date(formatString: "MMMM Do, YYYY")
                    }
                }
            }
        }
    }
`

export default BlogPage